const BACKEND_BASE_URL = process.env.VUE_APP_BACKEND_URL;
const REDIRECT_URL = process.env.VUE_APP_REDIRECT_URL;
const STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY;

// const BACKEND_BASE_URL = "https://127.0.0.1:8000/"
// const REDIRECT_URL = "https://127.0.0.1:8080/"
const GRAPH_ERROR_MESSAGE =
  'Uh oh, something went wrong A simple warning alert —check it out!';

// console.log("ENV is ", BACKEND_BASE_URL, process.env);

// load .env file

// get BACKEND_URL from env file

const FACEBOOK = {
  APP_ID: '811492343983632',
  REDIRECT_URL: REDIRECT_URL,
  SCOPE:
    'pages_show_list,business_management,pages_read_engagement,ads_management,ads_read,read_insights,pages_read_user_content,'
};

const INSTAGRAM = {
  APP_ID: '811492343983632',
  REDIRECT_URL: REDIRECT_URL,
  SCOPE:
    'pages_show_list,business_management,pages_read_engagement,ads_management,ads_read,read_insights,instagram_manage_insights,pages_read_user_content,instagram_basic'
};

const YOUTUBE = {
  SCOPE:
    'https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/yt-analytics-monetary.readonly https://www.googleapis.com/auth/youtubepartner',
  CLIENT_ID:
    '756940618946-u4o53h9bcdmg1uef87tq0q9it6h266i0.apps.googleusercontent.com',
  REDIRECT_URL: REDIRECT_URL
};

FACEBOOK[
  'LOGIN_URL'
] = `https://www.facebook.com/dialog/oauth?client_id=${FACEBOOK.APP_ID}&redirect_uri=${FACEBOOK.REDIRECT_URL}&scope=${FACEBOOK.SCOPE}&response_type=code`;
INSTAGRAM[
  'LOGIN_URL'
] = `https://www.facebook.com/dialog/oauth?client_id=${INSTAGRAM.APP_ID}&redirect_uri=${INSTAGRAM.REDIRECT_URL}&scope=${INSTAGRAM.SCOPE}&response_type=code`;
YOUTUBE[
  'LOGIN_URL'
] = `https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&access_type=offline&scope=${YOUTUBE.SCOPE}&response_type=code&client_id=${YOUTUBE.CLIENT_ID}&redirect_uri=${YOUTUBE.REDIRECT_URL}`;

const GRAPH_COLORS = {
  youtube: '#FF6666', // Lighter shade of red
  instagram: '#8A3A76', // Lighter shade of purple
  facebook: '#5593F5', // Lighter shade of blue
  benchmark_line: '#3A7AF1',
  // organge hex code
  score_line_color: '#90EE90', // Orange
  generic1: '#CCCCCC', // Light Gray
  generic2: '#ADD8E6', // Light Blue
  generic3: '#90EE90', // Light Green
  generic4: '#FFB6C1', // Light Pink
  generic5: '#E6E6FA', // Lavender
  generic6: '#F5F5DC', // Beige
  generic7: '#FFE5B4' // Peach
};

const GRAPH_TOOLTIP_CONTENT = {
  awareness: {
    ctr: 'This graph displays the Click Through Rate (CTR), which measures the percentage of people who clicked on your content out of the total who viewed it. A higher CTR indicates more effective and engaging content that prompts audience interaction. Note: These values are normalized.',
    video_views:
      'This graph displays the number of times your video content was viewed. Video views are a key metric for measuring the success of video content and gauging audience engagement. Note: These values are normalized.',
    post_vs_awareness:
      'This graph compares the number of posts with the awareness score. A higher awareness score indicates a greater level of brand recognition and visibility among your target audience. Note: These values are normalized.',
    top_posts:
      'This graph displays the top-performing posts based on the awareness score. These posts have generated the highest level of brand recognition and visibility among your target audience. Note: These values are normalized.'
  },
  engagement: {
    daily_views:
      'This graph displays the number of daily views on your content. Daily views are a key metric for measuring audience engagement and the effectiveness of your content strategy. Note: These values are normalized.',
    likes:
      'This graph displays the number of likes on your content. Likes are a key metric for measuring audience engagement and the popularity of your content. Note: These values are normalized.',
    comments:
      'This graph displays the number of comments on your content. Comments are a key metric for measuring audience engagement and the level of interaction with your content. Note: These values are normalized.',
    shares:
      'This graph displays the number of shares on your content. Shares are a key metric for measuring audience engagement and the reach of your content. Note: These values are normalized.',
    post_vs_engagement:
      'This graph compares the number of posts with the engagement score. A higher engagement score indicates a higher level of audience interaction and involvement with your content. Note: These values are normalized.'
  },
  consideration: {
    daily_posts_vs_consideration:
      'This graph compares the number of daily posts with the consideration score. A higher consideration score indicates a greater likelihood that your audience will consider your product or service as a solution to their needs or problems. Note: These values are normalized.',
    post_views_vs_consideration:
      'This graph compares the number of post views with the consideration score. A higher consideration score indicates a greater likelihood that your audience will consider your product or service as a solution to their needs or problems. Note: These values are normalized.',
    post_vs_consideration:
      'This graph compares the number of posts with the consideration score. A higher consideration score indicates a greater likelihood that your audience will consider your product or service as a solution to their needs or problems. Note: These values are normalized.'
  },
  conversion: {
    daily_posts_vs_conversion:
      'This graph compares the number of daily posts with the conversion score. A higher conversion score indicates a higher number of conversions or sales generated as a result of your marketing efforts. Note: These values are normalized.',
    post_views_vs_conversion:
      'This graph compares the number of post views with the conversion score. A higher conversion score indicates a higher number of conversions or sales generated as a result of your marketing efforts. Note: These values are normalized.',
    post_vs_conversion:
      'This graph compares the number of posts with the conversion score. A higher conversion score indicates a higher number of conversions or sales generated as a result of your marketing efforts. Note: These values are normalized.'
  },
  decision: {
    followers:
      'This graph displays the number of followers on your page. Followers are a key metric for measuring the size and reach of your audience. Note: These values are normalized.',
    post_upload_time_vs_decision:
      "This graph compares the post upload time with the decision score. A higher decision score indicates the effectiveness of your marketing efforts in influencing a customer's final purchasing decision. Note: These values are normalized.",
    post_vs_decision:
      "This graph compares the number of posts with the decision score. A higher decision score indicates the effectiveness of your marketing efforts in influencing a customer's final purchasing decision. Note: These values are normalized."
  },
  repeat: {
    daily_posts_vs_repeat:
      'This graph compares the number of daily posts with the repeat score. A higher repeat score indicates a higher probability of a customer making a repeat purchase or using your services again. Note: These values are normalized.',
    post_views_vs_repeat:
      'This graph compares the number of post views with the repeat score. A higher repeat score indicates a higher probability of a customer making a repeat purchase or using your services again. Note: These values are normalized.',
    post_vs_repeat:
      'This graph compares the number of posts with the repeat score. A higher repeat score indicates a higher probability of a customer making a repeat purchase or using your services again. Note: These values are normalized.'
  },
  brand_love: {
    daily_posts_vs_brand_love:
      'This graph compares the number of daily posts with the brand love score. A higher brand love score indicates the long-term commitment and emotional connection customers have with your brand. Note: These values are normalized.',
    post_video_views_vs_brand_love:
      'This graph compares the number of post views with the brand love score. A higher brand love score indicates the long-term commitment and emotional connection customers have with your brand. Note: These values are normalized.',
    post_vs_brand_love:
      'This graph compares the number of posts with the brand love score. A higher brand love score indicates the long-term commitment and emotional connection customers have with your brand. Note: These values are normalized.'
  },
  advocacy: {
    daily_post_vs_advocacy:
      'This graph compares the number of daily posts with the advocacy score. A higher advocacy score indicates the extent to which satisfied customers become advocates who promote your brand willingly. Note: These values are normalized.',
    post_frequency_vs_advocacy:
      'This graph compares the post frequency with the advocacy score. A higher advocacy score indicates the extent to which satisfied customers become advocates who promote your brand willingly. Note: These values are normalized.',
    post_vs_advocacy:
      'This graph compares the number of posts with the advocacy score. A higher advocacy score indicates the extent to which satisfied customers become advocates who promote your brand willingly. Note: These values are normalized.'
  },
  overview: {
    engagement_score:
      'The Engagement score measures the degree of interaction and involvement your audience has with your marketing content or brand. A higher Engagement score indicates a more engaged audience. Note: These values are normalized.',
    consideration_score:
      'The Consideration score measures the likelihood that your audience will consider your product or service as a solution to their needs or problems. A higher Consideration score indicates a greater likelihood of consideration. Note: These values are normalized.',
    conversion_score:
      'The Conversion score measures the number of conversions or sales generated as a result of your marketing efforts. A higher Conversion score indicates a higher number of conversions or sales. Note: These values are normalized.',
    advocacy_score:
      'The Advocacy score measures the extent to which satisfied customers become advocates who promote your brand willingly. A higher Advocacy score indicates a higher level of advocacy. Note: These values are normalized.',
    repeat_score:
      'The Repeat score measures the probability of a customer making a repeat purchase or using your services again. A higher Repeat score indicates a higher probability of repeat business. Note: These values are normalized.',
    brand_love_score:
      'The Brand Love score measures the long-term commitment and emotional connection customers have with your brand. A higher Brand Love score indicates a stronger emotional connection. Note: These values are normalized.',
    decision_score:
      "The Decision score measures the effectiveness of your marketing efforts in influencing a customer's final purchasing decision. A higher Decision score indicates more effective marketing efforts. Note: These values are normalized.",
    awareness_score:
      'The Awareness score measures the level at which your target audience recognizes your brand, product, or service. A higher Awareness score indicates a higher level of brand recognition. Note: These values are normalized.'
  }
};

export {
  GRAPH_ERROR_MESSAGE,
  GRAPH_COLORS,
  FACEBOOK,
  YOUTUBE,
  INSTAGRAM,
  BACKEND_BASE_URL,
  GRAPH_TOOLTIP_CONTENT,
  STRIPE_PUBLIC_KEY
};

import { createStore } from 'vuex'

import modules from './modules'


const store = createStore({
    modules,
    strict: process.env.NODE_ENV !== 'production',
    state: {
        user: {
            email: '',
            first_name: '',
            last_name: '',
            token: '',
            subscription_type: '',
            brands: []
        }
    },
    mutations: {
        setUser(state, userData) {
            state.user = userData;
        },
        updateUser(state, userData) {
            Object.assign(state.user, userData);
        }
    },
    actions: {
        login({ commit }, userData) {
            // Save user data
            console.log("saving data to vuex")
            commit('setUser', userData);
        },
        logout({ commit }) {
            // Clear user data on logout
            commit('setUser', {
                email: '',
                first_name: '',
                last_name: '',
                token: '',
                subscription_type: '',
                brands: []
            });
        }
    },
    getters: {
       email: state => state.user.email,
       first_name: state => state.user.first_name,
       last_name: state => state.user.last_name,
       token: state => state.user.token,
       brands: state => state.user.brands,
       subscription_type: state => state.user.subscription_type
    }
});

export default store;



import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";


import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import { vMaska } from "maska"

import VueFeather from 'vue-feather';

import '@/assets/scss/config/default/app.scss';
import "leaflet/dist/leaflet.css";
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'

import { createAuth0 } from '@auth0/auth0-vue';


// FakeBackend authentication
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();


// Firebase authentication
// import { initFirebaseBackend } from './authUtils';

// const firebaseConfig = {
//     apiKey: process.env.VUE_APP_APIKEY,
//     authDomain: process.env.VUE_APP_AUTHDOMAIN,
//     databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//     projectId: process.env.VUE_APP_PROJECTId,
//     storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.VUE_APP_MEASUREMENTID
// };

// initFirebaseBackend(firebaseConfig);


import axios from 'axios';
import Vue from 'vue';
import {BASE_URL} from '@/components/constant.js';
import {BACKEND_BASE_URL} from '@/components/constant.js';

axios.defaults.baseURL = BACKEND_BASE_URL;

// Set default headers
// 503d1b012e6d7a2ed9ae585e91f3dec30cdd27e6ea2f3416f61fc81503eac98b
axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.headers.common["Authorization"] = "Token 503d1b012e6d7a2ed9ae585e91f3dec30cdd27e6ea2f3416f61fc81503eac98b";

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});


const app = createApp(App);
app.config.globalProperties.$http = axios;

    
app
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .use(
        createAuth0({
            domain: "dev-wb1v1bgpvi08ao8a.us.auth0.com",
            clientId: "vvh40e6dBF1LgdhKLoYTkrOWrhmcWYiO",
            authorizationParams: {
              redirect_uri: window.location.origin
            }
          })
    )
    .component(VueFeather.type, VueFeather)
    .directive("maska", vMaska)
    .use(i18n)
    .use(vClickOutside).mount('#app');
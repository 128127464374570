/* eslint-disable */
import store from "@/state/store";

export default [
    {

        path: "/activate",
        name: "activate",
        component: () => import("../views/custom/activate.vue"),
        meta: {
            title: "activate",
        },
    },
    {
        path: "/awareness",
        name: "awareness",
        component: () => import("../views/custom/awareness.vue"),
        meta: {
            title: "awareness",
            authRequired: true
        },
    }, 
    {
        path: "/",
        name: "default",
        meta: {
            title: "Overview",
            authRequired: true,
        },
        component: () => import("../views/custom/overview.vue"),
    },
    // checkout
    {
        path: "/checkout",
        name: "checkout",
        component: () => import("../views/custom/checkout.vue"),
        meta: {
            title: "checkout",
            authRequired: true
        },
    },
    {
        path: "/subscription",
        name: "subscription",
        component: () => import("../views/custom/subscription.vue"),
        meta: {
            title: "subscription",
            authRequired: true
        },
    },
    {
        path: "/success",
        name: "success",
        component: () => import("../views/custom/success.vue"),
        meta: {
            title: "success",
            authRequired: true
        },
    },
    {
        path: "/cancel",
        name: "cancel",
        component: () => import("../views/custom/cancel.vue"),
        meta: {
            title: "cancel",
            authRequired: true
        },
    },
    
    {
        path: "/conversion",
        name: "conversion",
        component: () => import("../views/custom/conversion.vue"),
        meta: {
            title: "conversion",
            authRequired: true
        },
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("../views/custom/privacy-policy.vue"),
        meta: {
            title: "privacy-policy",
        },
    },
    {
        path: "/term-conditions",
        name: "term-conditions",
        component: () => import("../views/custom/term-conditions.vue"),
        meta: {
            title: "term-conditions",
        },

    },
    {
        path: "/consideration",
        name: "consideration",
        component: () => import("../views/custom/consideration.vue"),
        meta: {
            title: "consideration",
            authRequired: true
        },

    },

    {
        path: "/decision",
        name: "decision",
        component: () => import("../views/custom/decision.vue"),
        meta: {
            title: "decision",
            authRequired: true
        },
    },

    {
        path: "/repeat",
        name: "repeat",
        component: () => import("../views/custom/repeat.vue"),
        meta: {
            title: "repeat",
            authRequired: true
        },
    },

    {
        path: "/brand-love",
        name: "brand_love",
        component: () => import("../views/custom/brand_love.vue"),
        meta: {
            title: "brand_love",
            authRequired: true
        },
    },
    {
        path: "/overview",
        name: "overview",
        component: () => import("../views/custom/overview.vue"),
        meta: {
            title: "overview",
            authRequired: true
        },
    },
    //  advocacy.vue
    {
        path: "/advocacy",
        name: "advocacy",
        component: () => import("../views/custom/advocacy.vue"),
        meta: {
            title: "advocacy",
            authRequired: true
        },
    },

    {
        path: "/engagement",
        name: "engagement",
        component: () => import("../views/custom/engagement.vue"),
        meta: {
            title: "engagement",
            authRequired: true
        },
    },


    {
        path: "/404",
        name: "404",
        component: () => import("../views/custom/404.vue"),
        meta: {
            title: "404",
        },
    },
    {
        path: "/maps/leaflet",
        name: "maps/leaflet",
        component: () => import("../views/maps/leaflet"),
        meta: {
            title: "maps/leaflet",
        },
    },
    {
        path: "/create-password",
        name: "create-password",
        component: () => import("../views/custom/create_password.vue"),
        meta: {
            title: "create-password",
        },
    },
    {
        path: "/graphs",
        name: "graphs",
        component: () => import("../views/test-page/index.vue"),
        meta: {
            title: "graphs",
        },
    },
    {
        path: "/reset",
        name: "reset",
        component: () => import("../views/custom/reset.vue"),
        meta: {
            title: "reset",
        },
    },





    {
        path: "/profile",
        name: "profile",
        component: () => import("../views/custom/profile.vue"),
        meta: {
            title: "profile",
            authRequired: true
        },
    },

    {
        path: "/pricing",
        name: "pricing",
        component: () => import("../views/custom/pricing.vue"),
        meta: {
            title: "pricing",
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/custom/login.vue"),
        meta: {
            title: "Login",
        },
    },

    {
        path: "/signup",
        name: "signup",
        component: () => import("../views/custom/signup.vue"),
        meta: {
            title: "signup",
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("../views/custom/logout.vue"),
        meta: {
            title: "logout",
        },


    },


    {
        path: "/dashboard/crypto",
        name: "dashboard-crypto",
        meta: {
            title: "Crypto",
            authRequired: true,
        },
        component: () => import("../views/dashboard/crypto/index"),
    },
    {
        path: "/dashboard/projects",
        name: "dashboard-projects",
        meta: {
            title: "Projects", authRequired: true,
        },
        component: () => import("../views/dashboard/projects/index"),
    },
    {
        path: "/dashboard/crm",
        name: "dashboard-crm",
        meta: {
            title: "CRM", authRequired: true,
        },
        component: () => import("../views/dashboard/crm/index"),
    },
    {
        path: "/dashboard/analytics",
        name: "dashboard-analytics",
        meta: {
            title: "Analytics", authRequired: true,
        },
        component: () => import("../views/dashboard/analytics/index"),
    },
    {
        path: "/dashboard/nft",
        name: "dashboard-nft",
        meta: {
            title: "NFT Dashboard", authRequired: true,
        },
        component: () => import("../views/dashboard/nft/index"),
    },
    {
        path: "/dashboard/job",
        name: "dashboard-job",
        meta: {
            title: "Job Dashboard", authRequired: true,
        },
        component: () => import("../views/dashboard/job/index"),
    },
    {
        path: "/ui/alerts",
        name: "Alerts",
        meta: {
            title: "Alerts", authRequired: true,
        },
        component: () => import("../views/ui/alerts"),
    },
    {
        path: "/ui/buttons",
        name: "Buttons",
        meta: {
            title: "Buttons", authRequired: true,
        },
        component: () => import("../views/ui/buttons"),
    },
    {
        path: "/ui/cards",
        name: "Cards",
        meta: { title: "Cards", authRequired: true },
        component: () => import("../views/ui/cards"),
    },
    {
        path: "/ui/carousel",
        name: "Carousel",
        meta: { title: "Carousel", authRequired: true },
        component: () => import("../views/ui/carousel"),
    },
    {
        path: "/ui/dropdowns",
        name: "Dropdowns",
        meta: { title: "Dropdowns", authRequired: true },
        component: () => import("../views/ui/dropdowns"),
    },
    {
        path: "/ui/grid",
        name: "Grid",
        meta: { title: "Grid", authRequired: true },
        component: () => import("../views/ui/grid"),
    },
    {
        path: "/ui/images",
        name: "Images",
        meta: { title: "Images", authRequired: true },
        component: () => import("../views/ui/images"),
    },
    {
        path: "/ui/modals",
        name: "Modals",
        meta: { title: "Modals", authRequired: true },
        component: () => import("../views/ui/modals"),
    },
    {
        path: "/ui/offcanvas",
        name: "offcanvas",
        meta: { title: "Offcanvas", authRequired: true },
        component: () => import("../views/ui/offcanvas"),
    },
    {
        path: "/ui/progress",
        name: "Progress",
        meta: { title: "Progress", authRequired: true },
        component: () => import("../views/ui/progress"),
    },
    {
        path: "/ui/placeholders",
        name: "Placeholders",
        meta: { title: "Placeholders", authRequired: true },
        component: () => import("../views/ui/placeholders"),
    },
    {
        path: "/ui/accordions",
        name: "accordions",
        meta: { title: "Accordions", authRequired: true },
        component: () => import("../views/ui/accordions"),
    },
    {
        path: "/ui/tabs",
        name: "tabs",
        meta: { title: "Tabs", authRequired: true },
        component: () => import("../views/ui/tabs"),
    },
    {
        path: "/ui/typography",
        name: "Typography",
        meta: { title: "Typography", authRequired: true },
        component: () => import("../views/ui/typography"),
    },
    {
        path: "/ui/embed-video",
        name: "embed-video",
        meta: { title: "Embed Video", authRequired: true },
        component: () => import("../views/ui/embed-video"),
    },
    {
        path: "/ui/ribbons",
        name: "ribbons",
        meta: { title: "Ribbons", authRequired: true },
        component: () => import("../views/ui/ribbons"),
    },
    {
        path: "/ui/lists",
        name: "lists",
        meta: { title: "Lists", authRequired: true },
        component: () => import("../views/ui/lists"),
    },
    {
        path: "/ui/links",
        name: "links",
        meta: { title: "Links", authRequired: true },
        component: () => import("../views/ui/links"),
    },
    {
        path: "/ui/utilities",
        name: "utilities",
        meta: { title: "Utilities", authRequired: true },
        component: () => import("../views/ui/utilities"),
    },
    {
        path: "/ui/notifications",
        name: "notifications",
        meta: { title: "Notifications", authRequired: true },
        component: () => import("../views/ui/notifications"),
    },
    {
        path: "/ui/general",
        name: "General",
        meta: { title: "General", authRequired: true },
        component: () => import("../views/ui/general"),
    },
    {
        path: "/ui/colors",
        name: "Colors",
        meta: { title: "Colors", authRequired: true },
        component: () => import("../views/ui/colors"),
    },
    {
        path: "/ui/badges",
        name: "badges",
        meta: { title: "Badges", authRequired: true },
        component: () => import("../views/ui/badges"),
    },
    {
        path: "/ui/media",
        name: "media",
        meta: { title: "Media", authRequired: true },
        component: () => import("../views/ui/media"),
    },
    {
        path: "/widgets",
        name: "widgets",
        meta: { title: "Widgets", authRequired: true },
        component: () => import("../views/widgets/index"),
    },
    {
        path: "/icons/boxicons",
        name: "boxicons-icon",
        meta: { title: "Boxicons", authRequired: true },
        component: () => import("../views/icons/boxicons"),
    },
    {
        path: "/icons/materialdesign",
        name: "Material Design",
        meta: { title: "Material Design", authRequired: true },
        component: () => import("../views/icons/materialdesign"),
    },
    {
        path: "/icons/feather",
        name: "feather",
        meta: { title: "Feather", authRequired: true },
        component: () => import("../views/icons/feather"),
    },
    {
        path: "/icons/lineawesome",
        name: "lineawesome",
        meta: { title: "Line Awesome", authRequired: true },
        component: () => import("../views/icons/lineawesome"),
    },
    {
        path: "/icons/remix",
        name: "remix",
        meta: { title: "Remix", authRequired: true },
        component: () => import("../views/icons/remix"),
    },
    {
        path: "/icons/crypto",
        name: "crypto",
        meta: { title: "Crypto", authRequired: true },
        component: () => import("../views/icons/crypto"),
    },
    {
        path: "/tables/basic",
        name: "basic-table",
        meta: { title: "Basic Table", authRequired: true },
        component: () => import("../views/tables/basic"),
    },
    {
        path: "/tables/gridjs",
        name: "gridjs",
        meta: { title: "Grid JS", authRequired: true },
        component: () => import("../views/tables/grid/index"),
    },
    {
        path: "/form/advanced",
        name: "form-advanced",
        meta: { title: "Form Advance", authRequired: true },
        component: () => import("../views/forms/advanced"),
    },
    {
        path: "/form/elements",
        name: "form-elements",
        meta: { title: "Form Elements", authRequired: true },
        component: () => import("../views/forms/elements"),
    },
    {
        path: "/form/layouts",
        name: "Form Layouts",
        meta: { title: "Form Layouts", authRequired: true },
        component: () => import("../views/forms/layouts"),
    },
    {
        path: "/form/editors",
        name: "Form Editors",
        meta: { title: "Form Editors", authRequired: true },
        component: () => import("../views/forms/editors"),
    },
    {
        path: "/form/file-uploads",
        name: "File Uploads",
        meta: { title: "File Uploads", authRequired: true },
        component: () => import("../views/forms/file-uploads"),
    },
    {
        path: "/form/validation",
        name: "Form Validation",
        meta: { title: "Form Validation", authRequired: true },
        component: () => import("../views/forms/validation"),
    },
    {
        path: "/form/wizard",
        name: "Form Wizard",
        meta: { title: "Form Wizard", authRequired: true },
        component: () => import("../views/forms/wizard"),
    },
    {
        path: "/form/masks",
        name: "form-mask",
        meta: { title: "Form Mask", authRequired: true },
        component: () => import("../views/forms/masks"),
    },
    {
        path: "/form/pickers",
        name: "form-pickers",
        meta: { title: "Form Picker", authRequired: true },
        component: () => import("../views/forms/pickers"),
    },
    {
        path: "/form/range-sliders",
        name: "form-range-slider",
        meta: { title: "Form Range Slider", authRequired: true },
        component: () => import("../views/forms/range-sliders"),
    },
    {
        path: "/form/select",
        name: "form-select",
        meta: { title: "Form Select", authRequired: true },
        component: () => import("../views/forms/select"),
    },
    {
        path: "/form/checkboxs-radios",
        name: "form-checkboxs-radio",
        meta: { title: "Checkboxs & Radios", authRequired: true },
        component: () => import("../views/forms/checkboxs-radios"),
    },
    {
        path: '/advance-ui/animation',
        name: 'animation',
        meta: {
            title: "Animation", authRequired: true,
        },
        component: () => import('../views/advance-ui/animation')
    },
    {
        path: '/advance-ui/highlight',
        name: 'highlight',
        meta: {
            title: "Highlight", authRequired: true,
        },
        component: () => import('../views/advance-ui/highlight')
    },
    {
        path: '/advance-ui/scrollbar',
        name: 'scrollbar',
        meta: {
            title: "Scrollbar", authRequired: true,
        },
        component: () => import('../views/advance-ui/scrollbar')
    },
    {
        path: '/advance-ui/scrollspy',
        name: 'scrollspy',
        meta: {
            title: "Scrollspy", authRequired: true,
        },
        component: () => import('../views/advance-ui/scrollspy')
    },
    {
        path: '/advance-ui/sweetalerts',
        name: 'sweetalerts',
        meta: {
            title: "Sweet Alerts", authRequired: true,
        },
        component: () => import('../views/advance-ui/sweetalerts')
    },
    {
        path: '/advance-ui/swiper',
        name: 'Swiper Slider',
        meta: {
            title: "Swiper", authRequired: true,
        },
        component: () => import('../views/advance-ui/swiper')
    },
    {
        path: "/maps/google",
        name: "Google Maps",
        meta: {
            title: "Google Maps", authRequired: true,
        },
        component: () => import("../views/maps/google"),
    },
    {
        path: "/maps/leaflet",
        name: "Leaflet Maps",
        meta: { title: "Leaflet Maps", authRequired: true },
        component: () => import("../views/maps/leaflet/index"),
    },
];